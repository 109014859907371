import React from 'react'
import { css } from '@emotion/core'

import ShareIcon from './ShareIcon'

const titleStyle = css({
  color: '#AAB6E4',
  maxHeight: '45px',
  minHeight: '45px',
  fontSize: '14px',
  fontWeight: 400,
})

const viewsStyle = css`
  color: #8491c5;
  font-size: 12px;
  font-weight: 400;
`

const linkStyle = css`
  text-decoration: none;
  color: #8995c6;
  font-size: 12px;
  font-weight: 400;
  &:hover {
    opacity: 0.75;
  }
`

const footerStyles = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
`

const test = css`
  display: flex;
  flex-direction: column;
  padding: 0rem 1rem;
`

const Card = ({ content, title, subreddit, views, page, cardID }) => (
  <div>
    <div css={test}>
      <div>
        <a css={linkStyle} href={page} target="blank">
          {subreddit}
        </a>
      </div>
      <h1 css={titleStyle}>{title}</h1>
    </div>
    <div css={footerStyles}>
      <a href={content} target="none">
        <ShareIcon />
      </a>
      <p css={viewsStyle}>{views} points</p>
    </div>
  </div>
)

// Card.propTypes = {
//   siteTitle: PropTypes.string,
// }

// Card.defaultProps = {
//   siteTitle: ``,
// }

export default Card
