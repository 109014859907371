import PropTypes from 'prop-types'
import React from 'react'
import { css } from '@emotion/core'

const iconStyle = css`
  opacity: 0.75;
  &:hover {
    opacity: 1;
    cursor: pointer;
  }
`

export default () => (
  <svg
    css={iconStyle}
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16Z"
      fill="white"
      fill-opacity="0.01"
    />
    <mask
      id="mask0"
      mask-type="alpha"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="32"
      height="32"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0 0H32V32H0V0Z"
        fill="#1B2034"
      />
      <path
        opacity="0.01"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0 0H32V32H0V0Z"
        fill="white"
      />
    </g>
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13.2305 9.33334H18.7694C19.6609 9.33334 19.9842 9.42617 20.3101 9.60048C20.6361 9.77478 20.8919 10.0306 21.0662 10.3565C21.2405 10.6824 21.3333 11.0057 21.3333 11.8972V22C21.3333 22.3682 21.0348 22.6667 20.6666 22.6667C20.5224 22.6667 20.382 22.6199 20.2666 22.5333L16 19.3333L11.7333 22.5333C11.4387 22.7543 11.0209 22.6946 10.8 22.4C10.7134 22.2846 10.6666 22.1443 10.6666 22V11.8972C10.6666 11.0057 10.7595 10.6824 10.9338 10.3565C11.1081 10.0306 11.3639 9.77478 11.6898 9.60048C12.0157 9.42617 12.339 9.33334 13.2305 9.33334Z"
      fill="#051033"
      fill-opacity="0.01"
    />
    <mask
      id="mask1"
      mask-type="alpha"
      maskUnits="userSpaceOnUse"
      x="10"
      y="9"
      width="12"
      height="14"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.2305 9.33334H18.7694C19.6609 9.33334 19.9842 9.42617 20.3101 9.60048C20.6361 9.77478 20.8919 10.0306 21.0662 10.3565C21.2405 10.6824 21.3333 11.0057 21.3333 11.8972V22C21.3333 22.3682 21.0348 22.6667 20.6666 22.6667C20.5224 22.6667 20.382 22.6199 20.2666 22.5333L16 19.3333L11.7333 22.5333C11.4387 22.7543 11.0209 22.6946 10.8 22.4C10.7134 22.2846 10.6666 22.1443 10.6666 22V11.8972C10.6666 11.0057 10.7595 10.6824 10.9338 10.3565C11.1081 10.0306 11.3639 9.77478 11.6898 9.60048C12.0157 9.42617 12.339 9.33334 13.2305 9.33334Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask1)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8 8H24V24H8V8Z"
        fill="white"
      />
    </g>
  </svg>
)
