import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import { css } from '@emotion/core'

const wrapperStyles = css`
  margin-top: 4rem;
`

const headerStyles = css`
  text-align: center;
  color: white;
  text-fecoration: none;
  font-family: 'Reenie Beanie';
  font-size: 120px;
  font-weight: 300;
`

const Header = ({ siteTitle }) => (
  <div css={wrapperStyles}>
    <h1 css={headerStyles}>Top Fitty Memes</h1>
  </div>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
