import { css, Global } from '@emotion/core'
import React from 'react'
import { Helmet } from 'react-helmet'
import Modal from 'react-modal'
import Card from '../components/Card'
import Header from '../components/Header'
import spongebob from '../images/spongebob.png'

const globalStyles = css`
  * {
    margin: 0;
  }
  body {
    background-color: #21273f;
  }
  @keyframes fill {
    0% {
      transform: scaleX(0) scaleY(0);
    }
    100% {
      transform: scaleX(1) scaleY(1);
    }
  }
`

const layoutStyles = css`
  padding: 0 15rem;
  outline: 0;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(312px, 1fr));

  @media only screen and (max-width: 600px) {
    padding: 0 1rem;
  }
`

const imageStyles = css`
  height: 150px;
  width: 100%;
  border-radius: 0.25rem;
  object-fit: cover;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 4px 10px 0 rgba(0, 0, 0, 0.19);
  &:hover {
    cursor: pointer;
  }
`

const wrapperStyles2 = css`
  background: #2e3554;
  border-radius: 0.25rem;
  font-family: Roboto;
  box-shadow: 3px 10px 22px 5px rgba(0, 0, 0, 0.16);
  margin: 2rem;
  animation: fill 1s cubic-bezier(0.8, 0, 0.2, 1);

  @keyframes fill {
    0% {
      transform: scaleX(0) scaleY(0);
    }
    100% {
      transform: scaleX(1) scaleY(1);
    }
  }
`

const modalTitle = css`
  position: absolute;
  top: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  overflow: hidden;
  text-align: center;
`

const modalText = css`
  display: inline-block;
  color: white;
  font-size: 32px;
  font-family: 'Roboto';
  padding: 6px;
  text-align: center;
  @media only screen and (max-width: 600px) {
    font-size: 20px;
  }
`

const modalStyle = css`
  max-height: 100%;
  max-width: 100%;
`

const imageWrapperStyles = css`
  padding: 0.6rem;
`

Modal.defaultStyles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
  },
}

const listOfSub = [
  {
    subreddit: 'dankmemes',
    limit: 12,
  },
  {
    subreddit: 'rareinsults',
    limit: 3,
  },
  {
    subreddit: 'memeeconomy',
    limit: 3,
  },
  {
    subreddit: 'memes',
    limit: 12,
  },
  {
    subreddit: 'hmmm',
    limit: 8,
  },
  {
    subreddit: 'me_irl',
    limit: 12,
  },
  {
    subreddit: 'fellowkids',
    limit: 3,
  },
  {
    subreddit: 'wholesomememes',
    limit: 4,
  },
  {
    subreddit: 'programmerhumor',
    limit: 6,
  },
  {
    subreddit: 'gifs',
    limit: 5,
  },  
 {
    subreddit: 'suicidebywords',
    limit: 1,
  },
  {
    subreddit: 'BikiniBottomTwitter',
    limit: 2,
  },
  {
    subreddit: '2meirl4meirl',
    limit: 2,
  },
]

class IndexPage extends React.Component {
  constructor() {
    super()

    this.state = {
      data: [],
      itemLength: 8,
      modalIsOpen: false,
      content: 'https://ssl.gstatic.com/onebox/weather/64/partly_cloudy.png',
      title: 'placeholder',
    }

    this.openModal = this.openModal.bind(this)
    this.changePic = this.changePic.bind(this)
    this.closeModal = this.closeModal.bind(this)
  }

  async componentDidMount() {
    document.addEventListener('scroll', this.trackScrolling)

    const promises = listOfSub.map(({ subreddit, limit }) =>
      fetch(
        `https://www.reddit.com/r/${subreddit}/top/.json??sort=top&t=day&limit=${limit}`
      )
    )

    const responses = await Promise.all(promises)
    const data = responses.map(item => item.json())
    const results = await Promise.all(data)

    let test = []

    results.forEach(subreddit => {
      subreddit.data.children.forEach(post => {
        const test1 = post.data.url.split('.')
        const test2 = test1[test1.length - 1]

        if (['png', 'jpg', 'gif'].indexOf(test2) > 0) {
          test.push({
            content: post.data.url,
            title: post.data.title,
            subreddit: post.data.subreddit,
            page: 'https://www.reddit.com' + post.data.permalink,
            views: post.data.ups,
          })
        }
      })
    })

    this.setState({
      data: test
        .sort((first, second) => {
          if (second) {
            return second.views - first.views
          } else {
            return first.views
          }
        })
        .slice(0, 52),
    })
  }

  componentWillUnmount() {
    document.removeEventListener('scroll', this.trackScrolling)
  }

  trackScrolling = () => {
    const { itemLength } = this.state
    if (
      window.innerHeight + window.scrollY >= document.body.offsetHeight &&
      itemLength < 52
    ) {
      this.setState({ itemLength: itemLength + 8 })
    }
  }

  arrowKeyPress = (items, item) => e => {
    if (this.state.modalIsOpen === true) {
      const { itemLength } = this.state
      let currentItem = 0
      items.map(memeItem => {
        if (
          e.keyCode === 39 &&
          currentItem < items.length - 1 &&
          memeItem.content === item
        ) {
          this.changePic(
            items[currentItem + 1].content,
            items[currentItem + 1].title
          )
          if (currentItem % 8 === 6) {
            this.setState({ itemLength: itemLength + 8 })
          }
        }
        if (e.keyCode === 37 && currentItem > 0 && memeItem.content === item) {
          this.changePic(
            items[currentItem - 1].content,
            items[currentItem - 1].title
          )
        }
        currentItem = currentItem + 1
      })
    }
  }

  changePic = (items, ctitle) => {
    this.setState({ content: items })
    this.setState({ title: ctitle })
    this.setState({ modalIsOpen: true })
  }

  openModal() {
    this.setState({ modalIsOpen: true })
  }

  closeModal() {
    this.setState({ modalIsOpen: false })
  }

  render() {
    const items = this.state.data.slice(0, this.state.itemLength)

    return (
      <div>
        <Global styles={globalStyles} />
        <Helmet>
          <title>Top Fifty Memes</title>
          <meta name="title" content="Top Fifty Memes" />
          <meta name="description" content="Top fifty memes from Reddit" />
          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://topfiftymemes.com/" />
          <meta property="og:title" content="Top Fifty Memes" />
          <meta
            property="og:description"
            content="Top fifty memes from Reddit"
          />
          <meta property="og:image" content={spongebob} />
          <meta property="twitter:card" content="summary_large_image" />
          <meta property="twitter:url" content="https://topfiftymemes.com/" />
          <meta property="twitter:title" content="Top Fifty Memes" />
          <meta
            property="twitter:description"
            content="Top fifty memes from Reddit"
          />
          <meta property="twitter:image" content={spongebob} />

          <link
            rel="stylesheet"
            href="https://cdnjs.cloudflare.com/ajax/libs/normalize/8.0.1/normalize.min.css"
          />
          <link
            href="https://fonts.googleapis.com/css?family=Reenie+Beanie|Roboto"
            rel="stylesheet"
          />
          <script src="https://cdnjs.cloudflare.com/ajax/libs/clipboard.js/2.0.0/clipboard.min.js" />
        </Helmet>
        <Header />
        <div
          css={layoutStyles}
          onKeyDown={this.arrowKeyPress(items, this.state.content)}
          tabIndex="0"
        >
          {items.map(item => (
            <div css={wrapperStyles2}>
              <div css={imageWrapperStyles}>
                <img
                  src={item.content}
                  css={imageStyles}
                  onClick={() => this.changePic(item.content, item.title)}
                />
              </div>

              <Card
                cardID={item.content}
                title={item.title}
                subreddit={'r/' + item.subreddit}
                views={item.views}
                content={item.content}
                page={item.page}
              />
            </div>
          ))}
          <Modal
            isOpen={this.state.modalIsOpen}
            onAfterOpen={this.afterOpenModal}
            onRequestClose={this.closeModal}
            contentLabel="Modal for memes"
          >
            <div css={modalTitle}>
              <span css={modalText}>{this.state.title} </span>
            </div>
            <div
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                height: '90%',
                width: '80%',
                WebkitOverflowScrolling: 'touch',
                outline: 'none',
                transform: 'translate(-50%, -50%)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                zIndex: '-1',
              }}
              onClick={this.closeModal}
            >
              <img src={this.state.content} css={modalStyle} alt="Error" />
            </div>
          </Modal>
        </div>
      </div>
    )
  }
}

export default IndexPage
